import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { random } from 'lodash';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { sizes } from '../utils/breakpoints';

const Container = styled(Layout)({
  height: '100%',
  p: {
    padding: 0,
    margin: 0,
  },
  ul: {
    paddingLeft: 0,
    marginTop: '0px !important',
    color: '#fff',
    listStylePosition: 'outside',
  },
  li: {
    padding: '4px 0',
    marginLeft: '22px',
  },
  h2: {
    fontSize: '30px !important',
    fontWeight: 'normal',
    letterSpacing: '-1px',
    color: '#fff',
    margin: '24px 0 8px 0',
  },
  h3: {
    fontSize: '16px !important',
    fontWeight: 'normal',
    letterSpacing: '-1px',
    color: '#fff',
    margin: '8px 0px',
  },
});

const Header = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'baseline',
});

const Wrapper = styled.div({
  maxWidth: sizes.lg,
  padding: '16px var(--page-margin)',
});

const Log = styled.div({
  background: ({ randSeed }) =>
    `linear-gradient(${random(
      randSeed * 55
    )}deg, rgba(220,90,161,1) 0%, rgba(102,28,187,1) 75%)`,
  boxShadow:
    '0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12)',
  padding: '24px 36px',
  borderRadius: '16px',
  lineHeight: 1.8,
  a: {
    color: '#fff',
  },
});

const Column = styled.div({
  display: 'flex',
  flexDirection: 'column',
  margin: '32px auto auto auto',
  justifyContent: 'center',
  paddingBottom: '16px',
  'div:first-child': {
    paddingTop: 0,
  },
});

const ChangeLog = ({ html, publishDate, index }) => (
  <Wrapper>
    <Log randSeed={index}>
      <Header>
        <h2>Release Notes</h2>
        <h3>{publishDate}</h3>
      </Header>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Log>
  </Wrapper>
);

ChangeLog.propTypes = {
  html: PropTypes.string.isRequired,
  publishDate: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
};

const ChangeLogsPage = ({
  data: {
    allContentfulChangelog: { nodes: changelogs },
  },
}) => (
  <>
    <SEO
      title="Frame.io - Developer Site - Changelogs"
      description="Learn more about building with the Frame.io API using Developer Tokens, Webhooks, Custom Actions, and OAuth apps. "
    />
    <Container>
      <Column>
        {changelogs.map((changelog, index) => (
          <ChangeLog
            html={changelog.content.childMarkdownRemark.html}
            index={index + 1}
            title={changelog.title}
            publishDate={changelog.publishdate}
            contentfulId={changelog.contentful_id}
          />
        ))}
      </Column>
    </Container>
  </>
);

export const ChangelogQuery = graphql`
  query Changelogs {
    allContentfulChangelog(limit: 10) {
      nodes {
        content {
          childMarkdownRemark {
            html
          }
        }
        title
        publishdate
        contentful_id
      }
    }
  }
`;

ChangeLogsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ChangeLogsPage;
